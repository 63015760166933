import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallel, mobileAbout, mobileAboutParallel } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-7px" mb="-5px">
          <CFImage
            src={mobileAbout}
            w="100%"
            pv="5px"
            alt="Namiya Sushi About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={about} w="100%" alt="Namiya Sushi About" />
      </DefaultScreen>
    </CFView>
  )
}
